import {useState, useEffect} from 'react';
import ClientListItem, {Client} from '../Client/ClientListItem';
import ClientModal from '../Client/ClientModal';
import ClientDeleteModal from '../Client/ClientDeleteModal';
import DataModal, {ActiveClientType} from '../DataPoints/DataModal';
import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';
import './Account.css';
import { Types } from 'mongoose';

import logoutIcon from '../../assets/img/logout.svg';

const Logo = require('../../assets/img/logo.jpg');

const Account = () => {
  const [showClientModal, setShowClientModal] = useState(false);
  const [showDataModal, setShowDataModal] = useState(false);

  const [deleteClientInfo, setDeleteClientInfo] = useState({id:'', clientName:''});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [clientList, setClientList] = useState<Client[]>([]);
  const [activeClient, setActiveClient] = useState<any>({_id: '', name: '', dataPoints: []});

  const logout = () => {
		APIUtils.callGet('api/account/logout')
		.then(response => {
			NavUtils.redirectToRoot();
		})
		.catch(err => {
			NavUtils.redirectToRoot();
		});
	}
  const fetchClientList = () => {
    APIUtils.callGet('api/client/list')
    .then(response => {
      if (response.status !== 200) {
        return console.log("Error Fetching Client List");
      }
      setClientList(response.data);
    });
  }
  const confirmDeleteClient = (id: any, clientName: string) => {
    setDeleteClientInfo({id: id, clientName: clientName});
    setShowDeleteConfirmation(true);
  }
  const deleteClient = (id: any) => {
    APIUtils.callDelete(`api/client/delete/${id}`)
    .then(response => {
      if (response.status !== 200) {
        return console.log("Error Deleting Client");
      }
      removeClientFromList(response.data._id);
      setShowDeleteConfirmation(false);
    });
  }
  const addClientToList = (client: Client) => {
    let newList = [client, ...clientList];
    setClientList(newList);
  }
  const removeClientFromList = (id: any) => {
    let clientToRemove = clientList.findIndex((client) => {
      return client._id === id;
    });
    let newList = [...clientList.slice(0, clientToRemove), ...clientList.slice(clientToRemove + 1)];
    setClientList(newList);
  }
  const onClientClick = (client: ActiveClientType) => {
    setActiveClient(client);
    setShowDataModal(true);
  }

  useEffect(() => {
    if(showDataModal === false) {
      fetchClientList();
    }
  }, [showDataModal]);

  return(
    <>
      <ClientModal
        showClientModal={(show) => setShowClientModal(show)}
        addClient={(client) => addClientToList(client)}
        showModal={showClientModal}
      />
      <ClientDeleteModal 
        clientInfo={deleteClientInfo}
        showClientDeleteModal={(show) => setShowDeleteConfirmation(show)}
        deleteClient={(id: any) => deleteClient(id)}
        showModal={showDeleteConfirmation}
      />
      <DataModal
        activeClient={activeClient}
        showDataModal={(show) => setShowDataModal(show)}
        showModal={showDataModal}
      />
      <section id="Dashboard">
        
        <header>
          <div className="headerContentWrap">
            <img className="clientLogo" src={Logo} alt="" />
            <nav>
              <button className="logoutBtn" onClick={logout}>
                Logout
                <img src={logoutIcon} alt="" />
              </button>
            </nav>
          </div>
        </header>

        <div className="contentWrap">

        {clientList.length !== 0 ? (
          <div className="clientTable">
            <section className="titles">
              <article>Client</article>
              <article>ID</article>
              <article># Data Points</article>
              <article></article>
            </section>
            <div className="listing">
              {clientList.map((client: Client, index: number) => {
                return (
                  <ClientListItem
                    {...client}
                    key={index}
                    delete={(id: Types.ObjectId, clientName: string) => confirmDeleteClient(id, clientName)}
                    onClientClick={(client: any) => onClientClick(client)}
                  />);
              })}
            </div>
          </div>
          ) : (
            <div className="alert">
              No clients created. Click the button to add new client.
            </div>
          )
        }

        <button className="addBtn" onClick={() => setShowClientModal(true)}>+ Add Client</button>

        </div>

      </section>
    </>
  );
}

export default Account;