// import md5 from 'md5';
import React, {
  useState, 
  useRef, 
  useEffect
} from 'react';
import APIUtils from '../../utils/APIUtils';
import './Login.css';

import Logo from '../../assets/img/logo.jpg';
import loginImage from '../../assets/img/loginbg.jpg';

const Login = () => {
  const registerRef = useRef<Boolean>();
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

  const login = (loginEmail: string, loginPassword: string) => {
		let params = {
			email: loginEmail,
			password: loginPassword
		};

		APIUtils.callPost('api/account/login', params)
		.then(loginCallback)
		.catch((err) => {
			setLoginError('Unable To Login!');
      return setIsLoading(false);
		});
	}

	const onLogin = () => {
    setIsLoading(true);

		if(!loginPassword) {
      setIsLoading(false);
      setLoginError('Please enter your password.');
      return;
		}

		if(!loginEmail) {
      setIsLoading(false);
      setLoginError('Please enter a valid email address.');
      return;
		}
		
		login(loginEmail, loginPassword);
	}

  const loginCallback = (response: any) => {
		if(response.status !== 200) {
			setLoginError(response.message);
      return setIsLoading(false);
		}
		if (window.location.pathname !== '/dashboard') {
			return window.location.pathname = '/dashboard';
		}
	}

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 768);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <>
      <div id="Login">
        <section className="left">
          {!isMobile && (<img className="logo" src={Logo} alt="Logo" />)}
          <div className="loginFrmWrap">
            <div className="formWrap">
              <h1>Welcome</h1>
              {
                loginError && (
                  <p className="alert error">{loginError}</p>
                )
              }
              <div className="field">
                <label htmlFor="email">Email</label>
                <input 
                  type="email" 
                  name="email"
                  placeholder="email" 
                  value={loginEmail}
                  onChange={(e) => setLoginEmail(e.target.value)}
                />
              </div>
              <div className="field">
                <label htmlFor="password">
                  Password
                </label>
                <input 
                  type="password" 
                  name="password"
                  placeholder="password" 
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                />
              </div>
              {
                (isLoading) ? (
                  <button onClick={onLogin}><i className="loading">Loading...</i></button>
                ) : (
                  <button onClick={onLogin}>Login</button>
                )
              }
            </div>
          </div>
        </section>
        <section className="right" style={{backgroundImage: `url(${loginImage})`}}>
          {isMobile && (<img className="logo" src={Logo} alt="Logo" />)}
        </section>
      </div>
    </>
  );
}

export default Login;
