import React, {useRef} from 'react';
import { Types } from 'mongoose';
import APIUtils from '../../utils/APIUtils';

import trash from '../../assets/img/trash.svg';

export type DataPoint = {
  title?: string;
  coordinates?: {
    lat: number;
    lng: number;
  },
  createAt?: Date;
  _id: Types.ObjectId;
  clientId: Types.ObjectId;
  updateDataPoints: (newDataPoints: DataPoint[]) => void;
}

const DataListItem: React.FC<DataPoint> = props => {
  const dataPointRef = useRef<any>();
  const removeDataPoint = (clientId: Types.ObjectId, dataPointId: Types.ObjectId) => {
    APIUtils.callDelete('api/datapoint/delete/', {clientId: clientId, dataPointId: dataPointId})
    .then(response => {
      if (response.status !== 200) {
        return console.log("Error Deleting Data Point");
      }
      props.updateDataPoints(response.data.dataPoints);
    });
  }
  return (
    <section ref={dataPointRef} className="listingItem">
      <article className="capitalize">{props.title}</article>
      {props.coordinates && (
        <>
        <article>{props.coordinates.lat}</article>
        <article>{props.coordinates.lng}</article>
        </>
      )}
      <article>
        <button className="trashBtn" onClick={() => removeDataPoint(props.clientId, props._id)}>
          <img src={trash} alt="Delete" />
        </button>
      </article>
    </section>
  )
};

export default DataListItem;
