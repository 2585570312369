class NavUtils {
	static redirectToRoot() {
		if (window.location.pathname !== '/') {
			window.location.pathname = '/';
		}
	}
	static redirectToDashboard() {
		if (window.location.pathname !== '/dashboard') {
			window.location.pathname = '/dashboard';
		}
	}
}

export default NavUtils;