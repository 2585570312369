import React, {useState, useEffect, useRef} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import APIUtils from '../../utils/APIUtils';
import NavUtils from '../../utils/NavUtils';

import Login from '../Login/Login';
import Account from '../Account/Account';

import '../../assets/css/base.css';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const verifyRef = useRef(false);

  const updateState = (isLoading: boolean, isLoggedIn: boolean) => {
    setIsLoading(isLoading);
    setIsLoggedIn(isLoggedIn);
  }

  const verifyUserSession = () => {
    if (verifyRef.current) return setIsLoading(false);
    verifyRef.current = true;
		APIUtils.callGet('api/account/verify')
		.then(response => {
			if (
        response.status !== 200 ||
        typeof response !== 'object' ||  
        typeof response.data !== 'object'
      ) {
        updateState(false, false);
				const publicPages = ['/'];
				if (publicPages.includes(window.location.pathname)) {
					return;
				}
				return NavUtils.redirectToRoot();
			}
      updateState(false, true);
      return NavUtils.redirectToDashboard();
		})
		.catch(err => {
      console.log(err);
			updateState(false, false);
		});
	};

  useEffect(() => {
    verifyUserSession();
  });

  return (
    <Router>
      <Routes>
        <Route 
          path="/" 
          element={<Login />} 
        />
        <Route
          path="/dashboard"
          element={<Account />}
        />
      </Routes>
    </Router>
  )
}

export default App;
