import React from 'react';
import './ClientDeleteModal.css';

import alert from '../../assets/img/alert.svg';

export type ClientDeleteModalType = {
  clientInfo: {
    id: any;
    clientName: string;
  };
  showClientDeleteModal: (value: boolean) => void;
  deleteClient: (id: any) => void;
  showModal: boolean;
};

const ClientModal: React.FC<ClientDeleteModalType> = props => {
  return (
    <>
    {props.showModal && (
      <div className="modal clientDeleteModal">
        <div className="modalWrap">
          <section className="contentWrap">
            <div className="image">
              <img src={alert} alt="Warning" />
              <span className="circle small"></span>
              <span className="circle large"></span>
            </div>
            <h2>Are you sure you want to delete "{props.clientInfo.clientName}"?</h2>
            <p>Deleting this install will permanentally remove all records from the database. If the client currently has the globe pacakge installed, this could cause display errors.</p>
            <div className="btnGroup">
              <button className="cancelBtn" onClick={() => props.showClientDeleteModal(false)}>Cancel</button>
              <button className="deleteBtn" onClick={(id: any) => props.deleteClient(props.clientInfo.id)}>Delete</button>
            </div>
          </section>
        </div>
      </div>
    )}
    </>
  )
}

export default ClientModal;
